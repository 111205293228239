import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "../scss/custom.scss";
import "../scss/main.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import FooterWrapper from "./organisms/Footer";
import Header from "./organisms/Header";
import { AppProvider } from "../context/appContext";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";
import BackToTop from "./atoms/BackToTop";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <AppProvider>
      <Helmet>
        <link
          rel="icon"
          href="/favicon.ico"
          type="image/vnd.microsoft.icon"
        ></link>
      </Helmet>
      <main>
        <div className="bg-white sticky-top header-shadow header-wrapper">
          <Header />
        </div>
        {children}
        <FooterWrapper preFooter={data.preFooter} />
        <div className="cookie-wrapper">
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            containerClasses="container general-container"
            buttonClasses="btn btn-disasterPacificBlue"
            declineButtonClasses="btn btn-white"
            buttonWrapperClasses="gdpr-cta-wrapper"
            disableStyles
            disableButtonStyles
            declineButtonText="Decline"
            enableDeclineButton="true"
            cookieName="gatsby-gdpr-google-analytics"
          >
            <div
              id="popup-text"
              className="eu-cookie-compliance-message"
              role="document"
            >
              <h3>
                We use cookies on this site to help improve your online
                experience.
              </h3>
              <p>
                By clicking the 'Accept' button, you agree to carry on using
                cookies. Read our{" "}
                <a href="/privacy-policy-website" tabIndex="0">
                  Privacy Policy
                </a>{" "}
                to find out what cookies are used for and how to change your
                settings.
              </p>
            </div>
          </CookieConsent>
        </div>
        <BackToTop />
      </main>
    </AppProvider>
  );
};

export default Layout;
